import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import scrollTo from 'gatsby-plugin-smoothscroll';
import "../styles/main.scss";
import "../styles/index.scss";
import Logo from "../svgs/icon.svg";

// styles
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
};
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 20,
};

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
};

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
};

// data
const links = [
  {
    text: "Blog",
    url: "/blog",
    external: false,
    description: "",
    color: "green",
  },
  {
    text: "Git",
    url: "https://git.linus-h.de/explore",
    external: true,
    description: "Source Code einiger meiner Projekte",
    color: "green",
  },
  {
    text: "Instagram",
    url: "https://www.instagram.com/linus.h_",
    external: true,
    description: "bisschen Fotos und so",
    color: "green",
  },
  {
    text: "Status",
    url: "https://status.linus-h.de",
    external: true,
    description: "Status Monitoring meiner Services",
    color: "orange",
  },
  {
    text: "Datenschutz",
    url: "/privacy",
    description: "gibt's noch nicht",
    color: "red",
  },
  {
    text: "Impressum",
    url: "/imprint",
    description: "",
    color: "red",
  },
];

// markup
export default function Component() {
  return (
    <div>
      <header>
        <div id="headerContainer">
          <Logo id="mainLogo" />
          <h1>
            Linus H.
            <br />
            <span>— Hi 👋🏻 </span>
          </h1>
          <div id="scrollHint" onClick={() => scrollTo("main")} onKeyDown={() => scrollTo("main")} role="link" tabIndex="0">🠗</div>
        </div>
        <StaticImage src="../images/index.webp" />
      </header>

      <nav></nav>

      <main>
        <section>
          <p>
            <code>WIP</code>
          </p>
          <ul style={listStyles}>
          {links.map((link) => (
            <li key={link.url} style={{ ...listItemStyles, color: link.color }}>
              <span>
                {link.external ? (
                  <a href={`${link.url}`} rel="noreferrer" target="_blank">
                    {link.text}
                  </a>
                ) : (
                  <Link to={`${link.url}`}>{link.text}</Link>
                )}
                {link.new ? (
                  <span style={badgeStyle} aria-label="Neu Badge">
                    NEU!
                  </span>
                ) : null}
                <p style={descriptionStyle}>{link.description}</p>
              </span>
            </li>
          ))}
        </ul>
        </section>
        
        <section id="projekte">
          <h2>
            Projekte
          </h2>
        </section>
        <section>
          <h2>
            Blog - neuste Einträge
          </h2>
        </section>
        <section>
          <h2>
            Über mich
          </h2>
        </section>
      </main>
    </div>
  );
}
